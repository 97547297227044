import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
        path: "*",
        name: "Home",
        component: Home,
        redirect: "/index",
        children: [
            {
                path: "/index",
                name: "index",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/homePage/index.vue"),
            },
            {
                path: "/product",
                name: "product",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/product/index.vue"),
            },
            {
                path: "/DetailZ",
                name: "DetailZ",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/product/productDetail.vue"),
            },
            {
                path: "/DetailM",
                name: "DetailM",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/product/productDetailM.vue"),
            },
            {
                path: "/downLoad",
                name: "downLoad",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/downLoad/downLoad.vue"),
            },
            {
                path: "/newsReport",
                name: "newsReport",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/newsReport/newsReport.vue"),
            },
            {
                path: "/newsDetail",
                name: "newsDetail",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/newsReport/newsDetail.vue"),
            },
            {
                path: "/service",
                name: "service",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/service/service.vue"),
            },
            {
                path: "/contactUs",
                name: "contactUs",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/address/address.vue"),
            },
            {
                path: "/companyRef",
                name: "companyRef",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/companyRef/companyRef.vue"),
            },
            {
                path: "/consumerSolutions",
                name: "consumerSolutions",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/productSoultions/consumerSolutions.vue"),
            },
            {
                path: "/alotTerminal",
                name: "alotTerminal",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/productSoultions/alotTerminal.vue"),
            },
            {
                path: "/platformProduct",
                name: "platformProduct",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/productSoultions/platformProduct.vue"),
            },
            {
                path: "/industrySolution",
                name: "industrySolution",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/productSoultions/industrySolution.vue"),
            },
            {
                path: "/aboutus",
                name: "aboutus",
                redirect: "/aboutus/address",
                component: () =>
                    import ( /* webpackChunkName: "about" */ "../views/aboutus/index.vue"),
                children: [{
                        path: "/aboutus/address",
                        name: "address",
                        component: () =>
                            import ( /* webpackChunkName: "about" */ "../views/address/address.vue"),
                    },
                    {
                        path: "/aboutus/companyRef",
                        name: "companyRef",
                        component: () =>
                            import ( /* webpackChunkName: "about" */ "../views/companyRef/companyRef.vue"),
                    }
                ]
            },
        ]
    },

    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
    // },
];

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;