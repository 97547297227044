<template>
  <div id="home">
    <el-container>
      <el-header style="background:white;z-index: 999;">
        <div class="head">
          <div class="head_left">
            <img src="https://advertisement.szhqiot.top/hqsite/assets/lognew.png"
              style='width:170px;height:40px;margin-right:60px' />
            <div class="header_bottom">
              <span class="nav_css" v-for="(item, i) in  navData" :key="i"
                :class="activePage.indexOf(item.router) > -1 ? 'active' : ''" @click="handelJump(item)">
                <span v-if="i == 1" @mouseenter="toggleShow(1)"> {{ item.name }}</span>
                <span v-else> {{ item.name }}</span>
                <div class="nav_childern">
                  <div class="nav_data" v-for="(datas, index) in  item.children" :key="index"
                    @click.stop="handelJump(datas)" :class="activePage.indexOf(datas.router) > -1 ? 'active' : ''">
                    {{ datas.name }}</div>
                </div>
              </span>
            </div>
            <div class="selectBox" v-if="isShowSelect" @mouseleave="toggleShow(2)">
              <div v-for="(item, index) in dataList" :key="index" :class="selectedIndex == index ? 'active' : 'boxsty'"
                @click.stop="selectBox(item, index)">
                {{ item.value }}
              </div>
            </div>
          </div>
          <div class="head_right">
            <img src="https://advertisement.szhqiot.top/hqsite/assets/phones.png"
              style='width:18px;height:18px;margin:8px 8px 0 0;' />
            <div style='text-align:left;'>
              <div style="font-size:22px;color:#121212;"><span
                  style="font-size:16px;font-weight: 400;">商务咨询电话：</span>400-825-8822</div>
            </div>
          </div>
        </div>

      </el-header>

      <el-main>
        <router-view></router-view>
      </el-main>

      <el-footer>
        <div class="fotter_parent">
          <div class="box">
            <img src="https://advertisement.szhqiot.top/hqsite/assets/border.png" style='width:400px;height:210px;'
              class="boximg" />
            <div class="left">
              <img src="https://advertisement.szhqiot.top/hqsite/assets/gzh.png"
                style='width:132px;height:132px;margin-right:40px' />

              <img src="https://advertisement.szhqiot.top/hqsite/assets/tb.png" style='width:132px;height:132px;' />

            </div>
            <div class="center">
              <div class="btn" @click="goClick('index')">首页</div>
              <div class="btn" @click="goClick('alotTerminal')">产品解决方案</div>
              <div class="btn" @click="goClick('service')">商务合作</div>
              <div class="btn" @click="goClick('companyRef')">走进我们</div>
            </div>
            <div class="right">
              <div style="height:60px;"><img style="width:170px;height:40px;"
                  src="https://advertisement.szhqiot.top/hqsite/assets/logo1.png" alt=""></div>
              <div style="height:35px;">服务热线：400-825-8822 </div>
              <div style="height:35px;">公司地址：深圳市深南中路华强路口华强集团1号楼</div>
              <div style="height:35px;">企业邮箱：hqtech.sales@szhq.com</div>
            </div>
            <div class="bottomall" @click="gow()">深圳华强技术有限公司 粤ICP备05147053号</div>

          </div>

        </div>

      </el-footer>

    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      selectedIndex: 0,
      dataList: [
        { key: 'alotTerminal', value: "AIoT终端" },
        { key: 'platformProduct', value: "平台产品" },
        { key: 'consumerSolutions', value: "消费者解决方案" },
        { key: 'industrySolution', value: "行业解决方案" },
      ],
      // dataIndex: localStorage.getItem('flag') || 1,
      //industrySolution platformProduct consumerSolutions alotTerminal
      navData: [
        { name: '携手共创', router: "index", flag: 1, type: true, style: 'background: #2681D5' },
        { name: '产品和解决方案', router: "alotTerminal", flag: 2, type: false, style: 'background: #2681D5' },
        // { name: '智骑宝', router: "DetailZ", flag: 2, type: false, style: 'background: #01419A' },
        // { name: '蘑菇小兵', router: "DetailM", flag: 3, type: false, style: 'background: #01419A;' },
        // { name: '下载中心', router: "downLoad", flag: 4, type: false, style: 'background: #01419A' },
        { name: '商务合作', router: "service", flag: 5, type: false, style: 'background: #2681D5' },
        { name: '走进我们', router: "companyRef", flag: 6, type: false, style: 'background: #2681D5' },
        // {name:'联系我们', router:"contactUs", flag:6, type: false, style:'background: #01419A'},
        // {name:'关于我们', router:"aboutus", flag:5, type: false, style:'background: #01419A',
        //   children:[
        //     {name:'公司介绍', router:"companyRef", flag:6, type: false, style:'background: #01419A'},
        //     {name:'联系地址', router:"address", flag:6, type: false, style:'background: #01419A'}
        //   ]
        // }
      ],
      // 当前选中的页面
      activePage: "",
      isShowSelect: false
    }
  },
  // 监听路由跳转
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.activePage = val.fullPath;
      },
      immediate: true,
    }
  },
  methods: {
    goClick(item) {
      this.$router.push({ name: item })
    },
    selectBox(item, index) {
      this.selectedIndex = index;
      this.$router.push({ name: item.key })
      this.navData[1].router = item.key
      this.isShowSelect = false;
    },
    gow() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    },
    toggleShow(tag) {
      if (tag == 1) {
        this.isShowSelect = true;
      } else {
        this.isShowSelect = false;
      }
    },
    // 路由跳转
    handelJump(item) {
      if (item.flag == 2) {
        this.isShowSelect = !this.isShowSelect;
      } else {
        this.isShowSelect = false;
      }
      this.$router.push({ name: item.router })

    }
  }
};
</script>
<style lang="scss" scoped>
#home {
  height: 100%;
  width: 100%;
  min-width: 1200px;
}

.el-header,
.el-footer {
  background-color: #1A1A28;
  text-align: center;
  padding: 0;
}

.el-footer {
  height: 400px !important;
}

.el-header {
  height: 80px !important;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
}

.header_bottom {
  line-height: 45px;
}

.nav_css {
  display: inline-block;
  color: #000;
  width: 150px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
}

.nav_css:hover .nav_childern {
  opacity: 1;
}

.nav_childern {
  position: absolute;
  z-index: 999;
  opacity: 0;
}

.nav_data {
  position: relative;
  background: #fff;
  height: 50px;
  width: 150px;
  left: 0;
  top: -5px;
  color: #000000;
  line-height: 60px;
}

.nav_css:hover {
  color: #2681D5 !important;
  background: none;
}

.nav_data:hover {
  background: #2681D5;
  color: #fff;
}

.nav_data.active,
.nav_css.active {
  background: none !important;
  color: #2681D5;
  border-bottom: 2px solid #2681D5;
}

.nav_css.active:after {
  display: block;
  height: 2px;
  width: 28px;
  content: "";
  //background-color: blue;
  position: absolute;
  left: 61px;
  top: 51px;
}

.el-aside {
  background-color: #D3DCE6;
  padding: 0;
}

.btn {
  height: 45px;
  cursor: pointer;

}

.btn:hover {
  color: #2081D1;
}

.el-main {
  background-color: white;
  color: #333;
  padding: 0;
  padding-bottom: 20px;
  ;
  margin-top: 80px;
}



ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: white;
}

.el-main {
  padding: 0 !important;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.head {
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.head_left {
  position: relative;
  height: 25px;
  display: flex;
  box-sizing: border-box;
}

.head_right {
  display: flex;
  height: 100%;
  margin-top: 50px;
  padding-right: 20px;
}

.bottom_top {
  width: 1200px;
  height: 116px;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #4A4E89;
  position: relative;
}

.bottom_top_contant {
  height: 80px;
  color: white;
  text-align: left;
  font-size: 12px;
  line-height: 26px;
  padding: 18px 0 0 35px;
  position: relative;
  display: flex;
}


.bottom_bottom {
  width: 1200px;
  height: 199px;
  margin: 0 auto;
  display: flex;
}

.selectBox {
  position: absolute;
  left: 380px;
  top: 47px;
  height: 160px;
  width: 150px;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px 0px rgba(21, 39, 72, 0.5000);
  opacity: 0.9;
  border-radius: 0px 0px 30px 30px;

}

.boxsty {
  margin: 10px 0;
}

.selectBox .active {
  margin: 10px 0;
  color: #2081D1;
}

.boxsty:hover {
  color: #2081D1;
}

.bottom_bottom_left {
  width: 380px;
  height: 80px;


  color: white;
  text-align: left;
  font-size: 14px;
  line-height: 26px;
  padding: 15px 0 15px 35px;
}

.boximg {
  position: absolute;
}

.bottom_bottom_right {
  flex: 1;
  height: 100%;
  color: white;
  font-size: 14px;
  padding: 15px 0 15px 0;
  box-sizing: border-box;
  text-align: right;
}

.bottom_bottom_right_content {
  height: 65%;
  display: flex;
  justify-content: space-around;
}

.erweima {
  margin: 18px 0 0 55px;
  color: white;
  font-size: 10px;
  position: absolute;
  left: 365px;
}

.link_img {
  width: 31px;
  height: 31px;
  margin-right: 15px;
}



.el-footer {
  color: white;
  font-size: 14px;

  .fotter_parent {
    width: 1200px;
    margin: auto;

    .box {
      width: 100%;
      height: 150px;
      display: flex;
      margin-top: 100px;
      box-sizing: border-box;
      justify-content: space-between;
    }

  }
}

.left {
  width: 30%;
  position: relative;
  top: 35px;
  left: 20px;
}

.center {
  text-align: left;
  margin-top: 20px;
}

.bottomall {
  position: absolute;
  margin-top: 260px;
  margin-left: 460px;
}

.bottomall:hover {
  color: #2081D1;
}

.right {
  text-align: left;
  margin-top: 20px;
}
</style>
